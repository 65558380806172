import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import tw, { styled } from "twin.macro"
import "styled-components/macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import moment from "moment-timezone"
import _ from "lodash"
import { TZ } from "../constants"
import DiamondbacksImgBackup from "./DiamondbacksImgBackup"

const Container = styled.div`
  ${tw`mb-4 `}
`
const HeaderContainer = styled.div`
  ${tw`flex justify-between items-center uppercase mt-2.5 pb-2 mb-4 border-b-2`}
`

const Header = styled.div`
  ${tw`
    text-2xl md:text-5xl text-[#9561a9] font-bold
  `}
`

const ButtonLink = styled(Link)`
  ${tw`
    flex items-center justify-center 
    px-2 py-1  md:px-3.5 md:py-1.5
    border-2 border-black/70 rounded 
    hover:bg-black/70 transition-[background-color] duration-200
    bg-black text-white
    text-sm md:text-lg 
  `}
`

const PostsList = styled.div`
  ${tw`text-sm box-border grid grid-cols-1 sm:grid-cols-2 gap-8`}
`

const Post = styled(Link)`
  ${tw``}
`
const ImageContainer = styled.div`
  ${tw`w-full relative h-[200px]`}
`
const TitleContainer = styled.div`
  ${tw`mt-4`}
`
const Title = styled.div`
  ${tw`text-2xl font-bold uppercase`}
`
const Excerpt = styled.div`
  ${tw`mt-2 text-base text-gray-500`}
`
const StyledText = styled.span`
  ${tw`font-bold text-[#9561a9]`}
`
const StatsContainer = styled.div`
  ${tw`flex items-center mt-3`}
`
const Svg = styled.img`
  ${tw`w-5 h-5`}
`
const Date = styled.div`
  ${tw`ml-2 text-gray-500 text-xs font-bold`}
`

// game recaps to be added
const Headlines = () => {
  const data = useStaticQuery(graphql`
    query HeadlinesQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: { eq: "news-post" }
            publish: { eq: true }
          }
        }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 4
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            hero {
              pageImage {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
          excerpt(pruneLength: 150)
        }
      }
    }
  `)

  const recentPosts = data.allMarkdownRemark.nodes

  return (
    <Container>
      <HeaderContainer>
        <Header>HEADLINES</Header>
        <ButtonLink to="/news">VIEW ALL</ButtonLink>
      </HeaderContainer>
      <PostsList>
        {recentPosts.length > 0 &&
          recentPosts.map(
            ({ fields, excerpt, id, frontmatter: { title, hero, date } }) => {
              const formattedSlug = `/news/${moment(date)
                .tz(TZ)
                .format("YYYY-MM-DD")}-${_.kebabCase(title)}`

              const pageImage = _.get(hero, "pageImage")

              return (
                <Post to={formattedSlug} key={id}>
                  <ImageContainer>
                    {pageImage ? (
                      <GatsbyImage
                        image={getImage(pageImage)}
                        alt="news_image"
                        style={{ width: "100%", height: "100%" }}
                        imgStyle={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <DiamondbacksImgBackup aspectRatio={50} />
                    )}
                  </ImageContainer>
                  <TitleContainer>
                    <Title>{title}</Title>
                    <Excerpt>
                      {excerpt} <StyledText>read more</StyledText>
                    </Excerpt>
                  </TitleContainer>
                  <StatsContainer>
                    <Svg
                      src="/img/svg/clock-hour-4.svg"
                      alt="buy_ticket_link"
                    />
                    <Date>
                      {moment(date).tz(TZ).format("dddd, MMMM DD,  YYYY")}
                    </Date>
                  </StatsContainer>
                </Post>
              )
            }
          )}
      </PostsList>
    </Container>
  )
}

export default Headlines
